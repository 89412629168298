const loadSvgSprite = spriteUrl => {
    fetch(spriteUrl, {
        method: 'GET',
    })
        .then(response => response.text())
        .then(data => {
            const div = document.createElement('div');

            div.innerHTML = data;
            div.classList.add('svg-sprite');
            document.body.insertBefore(div, document.body.childNodes[0]);
        });
};

export { loadSvgSprite };
