import Elemental from 'jslib/elemental/elemental';
import events from 'jslib/custom-events';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Keyboard, Navigation, Pagination } from 'swiper/modules';

Elemental.Components.Carousel = class Carousel extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        const speed = this.element.dataset.speed;

        this.css = {
            classes: {
                open: 'open',
                hide: 'swiper-hide',
                currentSlide: 'swiper-slide-active',
            },
            selectors: {
                carousel: '.swiper',
                carouselArrow: '.swiper-button',
                nextArrow: '.swiper-button-next',
                prevArrow: '.swiper-button-prev',
            },
        };

        this.options = {
            allowTouchMove: this.element.dataset.disableTouchMove ? false : true,
            autoHeight: this.element.dataset.autoHeight ? true : false,
            initialSlide: this.element.dataset.initialSlide || 0,
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            loop: this.element.dataset.loop ? true : false,
            modules: [Autoplay, EffectFade, Navigation, Keyboard, Pagination],
            simulateTouch: false,
            slidesPerView: 1,
            spaceBetween: 16,
            speed: speed ? parseInt(speed) : 300,
            navigation: {
                nextEl: this.element.querySelector(this.css.selectors.nextArrow),
                prevEl: this.element.querySelector(this.css.selectors.prevArrow),
            },
        };

        if (this.element.dataset.arrows === 'false') {
            delete this.options.navigation;
        }

        if (this.element.dataset.pagination) {
            this.options.pagination = {
                el: '.swiper-pagination',
                clickable: true
            };
        }

        if (this.element.dataset.autoplay) {
            const delay = this.element.dataset.autoplayDelay;

            this.options.autoplay = {
                delay: delay ? parseInt(delay) * 1000 : 3000,
                pauseOnMouseEnter: true,
            };
        }

        if (this.element.dataset.responsive) {
            const slidesToShow = this.getResponsiveSlidesToShow();
            const slidesToScroll = this.getResponsiveSlidesToScroll();

            this.options.breakpoints = this.getResponsiveBreakpointConfig(this.globalObject.carousel.responsiveBreakpoints, slidesToShow, slidesToScroll);
        }

        if (this.element.dataset.effect === 'fade') {
            Object.assign(this.options, {
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
            });
        }

        this.carouselEl = this.element.querySelector(this.css.selectors.carousel);
        this.carouselArrowEls = this.element.querySelectorAll(this.css.selectors.carouselArrow);
    }

    setEventListeners() {
        this.bodyEl.addEventListener(events.carousel.resetBreakpoints, this.onResetBreakpoints.bind(this));
    }

    onResetBreakpoints(event) {
        const slidesToShow = this.getResponsiveSlidesToShow();
        const slidesToScroll = this.getResponsiveSlidesToScroll();

        if (this.options.breakpoints) {
            this.options.breakpoints = this.getResponsiveBreakpointConfig(this.globalObject.carousel.responsiveBreakpoints, slidesToShow, slidesToScroll);

            this.carousel.destroy();
            this.init();
        }
    }

    getResponsiveBreakpointConfig(breakpoints, slidesToShow, slidesToScroll) {
        const responsiveBreakpoints = {};

        breakpoints = breakpoints.toString().split(',');
        slidesToShow = slidesToShow.toString().split(',');
        slidesToScroll = slidesToScroll.toString().split(',');

        breakpoints.forEach((breakpoint, index) => {
            const width = parseInt(breakpoint, 10);

            responsiveBreakpoints[width] = {
                slidesPerView: parseFloat(slidesToShow[index]),
                slidesPerGroup: parseInt(slidesToScroll[index], 10),
            };
        });

        return responsiveBreakpoints;
    }

    getResponsiveSlidesToShow() {
        return this.globalObject.carousel.responsiveSlidesToShow;
    }

    getResponsiveSlidesToScroll() {
        return this.globalObject.carousel.responsiveSlidesToScroll;
    }

    init() {
        this.carousel = new Swiper(this.carouselEl, this.options);
    }

    render() {
        this.setVars();
        this.setEventListeners();
        this.init();
    }
};
