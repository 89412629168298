const pulseCustomEvents = {
    carousel: {
        resetBreakpoints: 'pb:carousel:resetBreakpoints',
    },
    images: {
        lazyLoaded: 'pb:images:lazyLoaded',
    }
};

export default pulseCustomEvents;
