const Elemental = {

    init: function(options) {
        this.options = options;
        this.instances = [];
        this.deferredComponentRequests = [];

        document.addEventListener('DOMContentLoaded', this.onDOMLoaded.bind(this));
    },

    onDOMLoaded: function(event) {
        this.initComponents();
        this.renderComponents();

        if (this.options.listManager) {
            this.options.listManager.init();
        }

        if (this.options.notificationManager) {
            this.options.notificationManager.init();
        }
    },

    initComponents: function() {
        const elements = document.querySelectorAll('[data-pb-component');

        elements.forEach((element, index) => {
            element.setAttribute('data-elemental-id', `element-${index + 1}`);
        });
    },

    renderComponents: function(parentEl = document.body) {
        const elements = parentEl.querySelectorAll('[data-pb-component]');

        elements.forEach(element => this.renderComponent(element));

        // Check if the parent element is also a component and needs to be rendered
        if (parentEl.dataset.pbComponent) {
            this.renderComponent(parentEl);
        }
    },

    renderComponent: function(element) {
        if (element.dataset.pbComponent && element.dataset.pbComponent.length) {
            switch (element.dataset.componentLoad) {
                case 'dynamic':
                    this.loadComponent(element.dataset.pbComponent)
                        .then(() => {
                            this.createComponent(element);
                        });

                    break;

                case 'intersection':
                    this.loadComponent(element.dataset.pbComponent)
                        .then(() => {
                            const observer = new IntersectionObserver((entries, observer) => {
                                entries.forEach(entry => {
                                    if (entry.isIntersecting) {
                                        this.createComponent(element);
                                        observer.unobserve(entry.target);
                                    }
                                });
                            },
                            {
                                threshold: 0.25,
                            });

                            observer.observe(element);
                        });

                    break;

                default:
                    this.createComponent(element);
            }
        }
    },

    createComponent: function(element) {
        const className = element
            .dataset
            .pbComponent
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join('');

        try {
            const newComponent = new this.Components[className](element, this.options);

            let exists = [];
            let render = false;

            newComponent.type = className;

            // Check to see if element already has components
            if (element.components && element.components.length) {
                // Check to see if this component has already been rendered on this element
                exists = element.components.filter(componentInstance => {
                    return componentInstance.type === className;
                });

                if (!exists.length) {
                    element.components.push(newComponent);
                    render = true;
                }
            } else {
                element.components = [newComponent];
                render = true;
            }

            if (render) {
                newComponent.render();
                this.instances.push(newComponent);

                // Handle any deferred getComponentById requests for this component
                const requests = this.deferredComponentRequests.filter(request => request.id === newComponent.id);

                if (requests.length) {
                    requests.forEach(request => {
                        request.resolve(newComponent);
                    });

                    // Reset deferred requests array
                    this.deferredComponentRequests = this.deferredComponentRequests.filter(request => request !== newComponent.id);
                }
            }
        } catch (exception) {
            if (exception instanceof TypeError) {
                console.error(`Error creating Elemental component ${className}: ${exception.message}`);
                throw exception;
                
            } else {
                throw exception;
            }
        }
    },

    getComponentById: function(id) {
        const promise = new Promise((resolve, reject) => {
            if (!id) {
                reject(new Error('No ID defined'));
            }

            const instance = this.getComponentInstance(id);

            if (instance) {
                resolve(instance);
            } else {
                this.deferredComponentRequests.push({
                    id: id,
                    resolve: resolve,
                });
            }
        });

        return promise;
    },

    getComponentInstance: function(id) {
        const instance = this.instances.filter(component => component.id === id);

        if (instance.length) {
            return instance[0];
        }

        return;
    },

    loadComponent: async function(componentName) {
        return await import(`../../../../components/${componentName}/js/${componentName}.js`);
    },

    Components: {},

    BaseComponent: class {
        constructor(element, options) {
            for (const key in options) {
                if (Object.prototype.hasOwnProperty.call(options, key)) {
                    this[key] = options[key];
                }
            }

            this.id = element.dataset.elementalId;
            this.element = element;
            this.bodyEl = document.querySelector('body');
            this.window = options.window;
        }

        emitEvent(targetElement, eventName, eventDetails) {
            if (typeof window.CustomEvent !== 'function' || !targetElement) return;

            const options = {
                bubbles: true,
            };

            if (eventDetails) {
                options.detail = eventDetails;
            }

            const event = new CustomEvent(eventName, options);

            targetElement.dispatchEvent(event);
        }
    },
};

export default Elemental;
