import '../scss/main.scss';

import Elemental from 'jslib/elemental/elemental';
import events from 'jslib/custom-events';
import { loadSvgSprite } from 'jslib/utils/utils';
import { Collapse, Dropdown } from 'bootstrap';
import LazyLoad from 'vanilla-lazyload/dist/lazyload';

import '../../components/carousel/js/carousel';

Elemental.init({
	window: window,
	globalObject: PETROLBLOG,
});

loadSvgSprite(PETROLBLOG.assets.svg);

PETROLBLOG.lazyLoad = new LazyLoad({
	elements_selector: '.lazy-load',
	callback_loaded: element => element.dispatchEvent(new CustomEvent(events.images.lazyLoaded)),
});
